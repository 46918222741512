import React, { FC, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useNavigation, useParams } from 'react-router-dom';
import { useLocalizedText } from '@ftdr/blueprint-components-react';
import Page from '../../components/Page';
import { useStoreContext } from '../../contexts/StoreContext';
import { PageRoute } from '../../constants/common';
import PageLoader from '../../components/PageLoader';

const Root: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { otp } = useParams();
  const navigation = useNavigation();
  const { ezLinkData } = useStoreContext();
  const [showLogin, setShowLogin] = useState(false);
  useEffect(() => {
    /**
     * Below line of code will redirect to ez-link page to load ezlink data
     * if user will reload the quote or add-payment-method page
     */
    if (location.pathname !== '/' && !location.pathname.includes('/ez-link') && !ezLinkData) {
      if (otp) {
        navigate(`/${PageRoute.EZLink.replace(':otp', otp)}`);
      }
    } else if (location.pathname === '/') {
      navigate(`/${PageRoute.PageNotFound}`);
    }

    if (
      location.pathname === '/create-account' ||
      location.pathname === '/create-account/myaccount' ||
      location.pathname === '/create-account/ahs-app'
    ) {
      setShowLogin(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const translate = useLocalizedText();

  return (
    <Page showLogin={showLogin}>
      {navigation.state === 'loading' ? <PageLoader label={translate('LOADING')} /> : <Outlet />}
    </Page>
  );
};

export default Root;

import { FC } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { PageRoute } from './constants/common';
import { PageNotFound } from './pages/PageNotFound';
import Root from './pages/Root';

const routes = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <PageNotFound />,
    children: [
      {
        path: PageRoute.EZLink,
        async lazy() {
          const { EZLink } = await import('./pages/EZLink');
          return {
            Component: EZLink
          };
        }
      },
      {
        path: PageRoute.Quote,
        async lazy() {
          const { Quote } = await import('./pages/Quote');
          return {
            Component: Quote
          };
        }
      },
      {
        path: PageRoute.AddPaymentMethod,
        async lazy() {
          const { AddPaymentMethod } = await import('./pages/AddPaymentMethod');
          return {
            Component: AddPaymentMethod
          };
        }
      },
      {
        path: PageRoute.Confirmation,
        async lazy() {
          const { Confirmation } = await import('./pages/Confirmation');
          return {
            Component: Confirmation
          };
        }
      },
      {
        path: PageRoute.CreateAccount,
        async lazy() {
          const { CreateAccount } = await import('./pages/CreateAccount');
          return {
            Component: CreateAccount
          };
        }
      },
      {
        path: PageRoute.MyAccountSetUp,
        async lazy() {
          const { MyAccountSetUp } = await import('./pages/MyAccountSetUp');
          return {
            Component: MyAccountSetUp
          };
        }
      },
      {
        path: PageRoute.AhsAppSetUp,
        async lazy() {
          const { AhsAppSetUp } = await import('./pages/AhsAppSetUp');
          return {
            Component: AhsAppSetUp
          };
        }
      }
    ]
  }
]);

const AppRoutes: FC = () => <RouterProvider router={routes} />;

export default AppRoutes;

import { FC, ReactNode } from 'react';

interface Props {
  className?: string;
  children: ReactNode;
}

const PageContent: FC<Props> = ({ className, children }) => (
  <div className={`container mx-auto px-4 xl:px-0 ${className}`}>{children}</div>
);

export default PageContent;

import React, { useCallback } from 'react';
import { Ahs2024LogoColor, GlobalHeader, HsaLogoColor, useLocalizedText } from '@ftdr/blueprint-components-react';
import { Brand } from '../../constants/Brand';
import configHandler from '../../services/config';

interface Props {
  showLogin: boolean;
}

const Header: React.FC<Props> = ({ showLogin }) => {
  const brand = configHandler.config?.site.name;
  const localizedText = useLocalizedText();
  const headerLogoRenderer = useCallback(() => {
    return brand === Brand.AHS ? <Ahs2024LogoColor height={50} /> : <HsaLogoColor height={50} />;
  }, [brand]);

  return (
    <GlobalHeader
      sticky
      constrainContentWidth
      logoRenderer={
        showLogin
          ? () => (
              <a
                className='flex-1 md:flex-none mr-6 md:mr-0'
                href={configHandler.config?.site.myAccountDomain}
                target='_blank'
                rel='noreferrer'>
                {headerLogoRenderer()}
              </a>
            )
          : headerLogoRenderer
      }
      navMenuItems={[]}
      onNavMenuItemActivate={_ => {}}
      desktop={true}
      ctaButtons={
        showLogin
          ? [
              {
                label: localizedText('AHS_APP_GTM.CTA.LOGIN'),
                variant: 'filled',
                size: 'medium',
                className: 'interactive',
                href: configHandler.config?.site.myAccountDomain,
                placementOnMobile: 'main-header',
                external: true
              }
            ]
          : []
      }
    />
  );
};

export default Header;

import { Notification, useLocalizedText } from '@ftdr/blueprint-components-react';
import PageContent from '../../components/PageContent';
import Header from '../../components/Header/Header';

const ErrorPage = ({ label }: { label?: string | JSX.Element }) => {
  const t = useLocalizedText();

  return (
    <>
      <Header showLogin={false} />
      <PageContent>
        <Notification
          variant='inline'
          children={label ? label : t?.('SERVER_ERROR')}
          showStatusLabel={false}
          status='error'
          matchColor
        />
      </PageContent>
    </>
  );
};

export default ErrorPage;

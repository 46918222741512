import { FC, PropsWithChildren } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
interface Props {
  showLogin: boolean;
}
const Page: FC<PropsWithChildren<Props>> = ({ showLogin, children }) => (
  <div className={`w-full ${showLogin ? 'bg-picket-white' : ''}`}>
    <Header showLogin={showLogin} />
    <div>{children}</div>
    <Footer />
  </div>
);

export default Page;
